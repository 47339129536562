<div class="container g-0">
  <div class="row mb-3">
    <div class="col d-flex justify-content-around mt-1">
      <img src="./assets/images/logo-boekstart.svg" />
      <img src="./assets/images/logo-dbos.svg" />
    </div>
  </div>
  <div>
    <h1>Monitor BoekStart en de Bibliotheek <i>op school</i></h1>
    <p class="mb-2">
      Op deze pagina kun je jezelf aanmelden voor de Monitor BoekStart, en voor de Monitor de Bibliotheek <i>op school</i> po, vo<span
        *ngIf="!isProduction()"
        >, mbo, pabo</span
      >.
      <br />
      <br />
      Voer hieronder de gegevens van de bibliotheek in en verstuur het verzoek voor registratie. Binnen enkele werkdagen ontvang je van de
      <a href="mailto:helpdesk@mdbos.nl">helpdesk</a>
      op het opgegeven e-mailadres een uitnodiging voor registratie.
      <br />
      <br />
      <br />
      Alvast bedankt voor je aanmelding!
    </p>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Bibliotheek gegevens</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form #form="ngForm" (ngSubmit)="save(form)">
          <div class="row mt-3">
            <div class="col-6 d-flex flex-column">
              <div class="mb-3"><strong>Gegevens van de bibliotheek</strong></div>
              <mat-form-field appearance="fill">
                <mat-label>Bibliotheek:</mat-label>
                <input
                  autocomplete="new-password"
                  matInput
                  autocompleteValidator
                  [matAutocomplete]="auto"
                  placeholder="zoek op naam, plaats, provincie of ISIL-code"
                  [(ngModel)]="selected"
                  name="selected"
                  required
                  (ngModelChange)="filterLibraries($event)"
                />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let library of filteredLibraries | async" [value]="library">
                    <span>{{ library.name }}</span> |
                    <small> ({{ library.province }})</small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-6 d-flex flex-column">
              <div class="mb-3"><strong>Gegevens van de Monitorcoördinator</strong></div>
              <mat-form-field appearance="fill">
                <mat-label>Naam:</mat-label>
                <input matInput [(ngModel)]="coordinator.name" #nameRef="ngModel" name="Name" required />
                <mat-error *ngIf="nameRef.hasError('required')"> Het veld 'Naam' is verplicht. </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>E-mailadres:</mat-label>
                <input matInput [(ngModel)]="coordinator.email" #emailRef="ngModel" name="Email" #ngModel="ngModel" mxRecordValidator required />
                <mat-error *ngIf="emailRef.hasError('mxRecordValidator')">{{ emailRef.getError("mxRecordValidator") }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <button mat-raised-button color="primary" pmaMdBos>Verzoek voor registratie versturen</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
