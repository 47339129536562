import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserError } from '@shared/util/infrastructure';
import { firstValueFrom } from 'rxjs';
import { UriService } from '../../core/uri.service';
import { StartCodeClient, UpdateSampleClient } from '../../core/webapi';
import { MatMdBosIconDirective } from '../../shared/mdbos-button.directive';

@Component({
  selector: 'pma-questionnaire',
  templateUrl: './questionnaire.component.html',
  imports: [
    NgIf,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    FormsModule,
    MatFormField,
    MatInput,
    MatError,
    MatButton,
    MatMdBosIconDirective,
  ],
})
export class QuestionnaireComponent implements OnInit {
  code: string | null = null;
  url!: SafeResourceUrl;
  guid!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private client: StartCodeClient,
    private sampleClient: UpdateSampleClient,
    private uris: UriService,
    private sanitizer: DomSanitizer,
  ) {}

  async ngOnInit(): Promise<void> {
    const startCode = this.activatedRoute.snapshot.params['startcode'];
    const index = this.activatedRoute.snapshot.params['index'];
    if (startCode.length === 6) {
      await this.redirect(startCode, index);
    }
  }

  async start() {
    this.code = this.code?.trim() ?? '';
    if (this.code.length !== 6) {
      throw new UserError(`Ongeldige startcode: ${this.code}. De startcode moet 6 tekens bevatten`, `Ongeldige startcode`);
    }
    await this.redirect(this.code, '');
  }

  private async redirect(code: string, index: string) {
    const guid = (this.guid = await firstValueFrom(this.client.getUrlLink(code!)));
    this.sampleClient.updateSample(code);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.uris.urlResponse}${guid}/${code}/${index}`);
  }
}
