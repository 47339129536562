import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { saveFile } from '@shared/util/code';
import { getEnvironment } from '@shared/util/infrastructure';
import { firstValueFrom } from 'rxjs';
import { Monitor } from '../../core/webapi';
import { DocumentClient } from '../../core/webapi/apiClient';
import { MatMdBosIconDirective } from '../../shared/mdbos-button.directive';

@Component({
  selector: 'pma-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    MatCardActions,
    MatAnchor,
    MatMdBosIconDirective,
    MatTooltip,
    MatDivider,
    MatButton,
  ],
})
export class OverviewComponent {
  readonly portal = Monitor;

  constructor(private document: DocumentClient) {}

  async onDownload(portal: Monitor) {
    const documentType = this.getDocumentType(portal);
    const file = await firstValueFrom(this.document.getDocument(documentType));
    saveFile(file);
  }

  isProduction() {
    return ['production'].includes(getEnvironment(window.location));
  }

  private getDocumentType(portal: Monitor): string {
    switch (portal) {
      case Monitor.BookStart:
        return 'BookStart';
      case Monitor.Vmbo:
        return 'Vo';
      case Monitor.Mbo:
        return 'Mbo';
      case Monitor.Pabo:
        return 'Pabo';
      default:
        return 'Po';
    }
  }
}
