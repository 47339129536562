import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AutoCompleteValidatorDirective, MxRecordValidatorDirective } from '@shared/ui/directives';
import { getEnvironment } from '@shared/util/infrastructure';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { LibraryService } from '../../core/library.service';
import { UriService } from '../../core/uri.service';
import { LibraryModel, LibraryRegistrationModel, LibraryUserClient } from '../../core/webapi';
import { MatMdBosIconDirective } from '../../shared/mdbos-button.directive';

@Component({
  selector: 'pma-registration-monitor-coordinator',
  templateUrl: './registration-monitor-coordinator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    AutoCompleteValidatorDirective,
    MatAutocomplete,
    NgFor,
    MatOption,
    NgIf,
    MatError,
    MxRecordValidatorDirective,
    MatButton,
    MatMdBosIconDirective,
    AsyncPipe,
    NgIf,
  ],
})
export class RegistrationMonitorCoordinatorComponent {
  readonly coordinator: LibraryRegistrationModel = {} as LibraryRegistrationModel;
  selected: LibraryModel | null = null;
  readonly filteredLibraries = new ReplaySubject<LibraryModel[]>(1);

  constructor(
    private route: ActivatedRoute,
    private libraryService: LibraryService,
    private libraryClient: LibraryUserClient,
    private snackBar: MatSnackBar,
    private uri: UriService,
  ) {
    this.filteredLibraries.next(libraryService.libraries);
  }

  filterLibraries(search: string | LibraryModel) {
    if (!search) {
      this.filteredLibraries.next(this.libraryService.libraries.slice());
      return;
    }
    const lowercase = (typeof search === 'string' ? search : search.name).toLowerCase();
    const list = this.libraryService.libraries.filter((x) => (x.name + x.province + x.isilCode).toLowerCase().includes(lowercase));
    this.filteredLibraries.next(list);
  }

  async save(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this.coordinator.loginUrl = this.uri.newLoginUrl;
    this.coordinator.id = this.selected!.id;
    await firstValueFrom(this.libraryClient.registrationRequestLibraryCoordinator(this.coordinator));
    this.snackBar.open(`Er is een e-mail gestuurd naar de helpdesk met een registratieverzoek.`);
  }

  displayFn(library: LibraryModel | undefined): string {
    return library && library.name ? library.name : '';
  }

  isProduction() {
    return ['production'].includes(getEnvironment(window.location));
  }
}
