import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { DynamicTableComponent, IColumn } from '@shared/ui/dynamic-table';
import { Monitor, ProgressModel, ReportModel, ResponseStatus } from '../../core/webapi';
import { getSurveyStatusToolTip } from '../../portal/library/registration/registration-texts';
import { RegistrationReportService } from './registration-report.service';

@Component({
  selector: 'pma-registration-report',
  templateUrl: './registration-report.component.html',
  imports: [MatCard, MatCardHeader, MatCardTitle, MatCardContent, NgIf, MatTabGroup, MatTab, DynamicTableComponent],
})
export class RegistrationReportComponent {
  readonly reportModel: ReportModel;
  readonly progressModels: ProgressModel[];
  readonly monitor = Monitor;
  readonly columnDefinitionsBookStart: Array<IColumn<ProgressModel, ReportModel>> = [
    {
      name: 'group',
      text: 'Groep',
      widths: [100, 100, 300],
    },
    {
      name: 'surveyStatus',
      text: 'Status vragenlijst',
      type: 'icon',
      disabled: true,
      widths: 'icon',
      filterFunc: null,
      toolTip: (row) => getSurveyStatusToolTip(row.surveyStatus, 'medewerker'),
      valueFunc: (row) =>
        row.surveyStatus === ResponseStatus.Completed ? 'flag' : row.surveyStatus === ResponseStatus.Started ? 'edit_note' : 'hourglass_empty',
    },
  ];

  readonly columnDefinitionsGroup: Array<IColumn<ProgressModel, ReportModel>> = [
    {
      name: 'group',
      text: [(Monitor.Vmbo, Monitor.Mbo, Monitor.Pabo)].includes(this.service.reportModel.portal) ? 'Klas' : 'Groep',
      widths: [100, 100, 300],
    },
    {
      name: 'surveyFinishStudents',
      widths: [100, 100, 300],
      text: 'Afgerond aantal (leerlingen)',
      toolTip: 'Aantal vragenlijsten afgerond door leerlingen',
      type: 'int',
      filterFunc: null,
    },
  ];

  readonly columnDefinitionsTeacher: Array<IColumn<ProgressModel, ReportModel>> = [
    {
      name: 'group',
      text: 'Groep',
      widths: [100, 100, 300],
    },
    {
      name: 'surveyStatus',
      text: 'Status vragenlijst',
      type: 'icon',
      disabled: true,
      widths: 'icon',
      filterFunc: null,
      toolTip: (row) => getSurveyStatusToolTip(row.surveyStatus, 'medewerker'),
      valueFunc: (row) => (row.surveyStatus === ResponseStatus.Completed ? 'flag' : row.surveyStatus === ResponseStatus.Started ? 'edit_note' : ''),
    },
  ];

  constructor(public service: RegistrationReportService) {
    this.reportModel = this.service.reportModel;
    this.progressModels = this.reportModel.progressModels;
  }
}
