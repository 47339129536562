import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ThemeColorService } from '@shared/feature/theme-service';
import { ColorConfig, ThemeMainColorType } from '@shared/feature/themes';

@Component({
  selector: 'pma-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet, LoadingBarModule],
})
export class AppComponent {
  accentColor: ColorConfig;
  constructor(themeColorService: ThemeColorService) {
    this.accentColor = themeColorService.getColorByVariant(ThemeMainColorType.accentColor, '500');
  }
}
