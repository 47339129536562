<lsu-report-paged [initialize]="isReady" [showPreview]="true" [showPageNumbers]="'Pagina'">
  <lsu-report-paged-actions [fileName]="fileName" [generatePdfFromHTML]="true"></lsu-report-paged-actions>
  <lsu-report-paged-loader></lsu-report-paged-loader>

  <lsu-report-paged-page>
    <div class="w-100 text-center">
      <div class="mat-headline-5 mb-7">Rapportage</div>
      <img [src]="coverContent.logo" width="200" class="mb-7" />
      <div class="mat-subtitle-1 mb-7">
        <strong>{{ coverContent.title }}</strong>
      </div>
      <div *ngIf="selectedLocations.locations.length" class="mat-body mb-7">Antwoorden per locatie</div>
      <div *ngIf="selectedLocations.locations.length" class="mb-7">
        <ng-container *ngIf="selectedLocations.locations.length <= 5">
          <div class="mat-subtitle-1 mb-0" *ngFor="let location of selectedLocations.locations">
            <strong>
              {{ location }}
            </strong>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedLocations.locations.length > 5">
          <div class="mat-subtitle-1 mb-0">
            <strong>Voor deze selectie zijn meer dan 5 {{ selectedLocations.locationType }} meegenomen</strong>
          </div>
        </ng-container>
      </div>
    </div>
  </lsu-report-paged-page>

  <lsu-report-paged-page *ngFor="let chartData of highchartData | keyvalue: originalOrder; let i = index">
    <pma-report-chart-column
      (rendered)="rendered()"
      class="chart"
      [print]="true"
      [reflow]="reflow"
      [data]="chartData.value"
      *ngIf="!isMultiResponseQuestion(chartData.key) && chartData.value"
    ></pma-report-chart-column>
    <pma-report-chart-bar
      class="chart"
      (rendered)="rendered()"
      [print]="true"
      [reflow]="reflow"
      [data]="chartData.value"
      *ngIf="isMultiResponseQuestion(chartData.key) && chartData.value"
    ></pma-report-chart-bar>
  </lsu-report-paged-page>
</lsu-report-paged>
