import { DatePipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogConfirmComponent, DialogConfirmData } from '@shared/ui/dialog';
import { LoadingIndicatorService } from '@shared/util/infrastructure';
import { firstValueFrom } from 'rxjs';
import { UriService } from '../../core/uri.service';
import { AuthorizeStatus, Monitor, SchoolRegistrationClient } from '../../core/webapi';
import { MatMdBosIconDirective } from '../../shared/mdbos-button.directive';

@Component({
  selector: 'pma-school-authorization',
  templateUrl: './school-authorization.component.html',
  imports: [
    NgIf,
    MatCard,
    FormsModule,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    MatCheckbox,
    MatCardActions,
    MatButton,
    MatMdBosIconDirective,
    DatePipe,
  ],
})
export class SchoolAuthorizationComponent {
  readonly validationCode: string;
  readonly monitor = Monitor;
  data!: AuthorizeStatus;
  formSaved = false;

  constructor(
    route: ActivatedRoute,
    private client: SchoolRegistrationClient,
    private dialog: MatDialog,
    private uri: UriService,
    private loadingIndicator: LoadingIndicatorService,
  ) {
    this.validationCode = route.snapshot.queryParams['ValidationCode'];
    void this.getAuthorizeStatus();
  }

  async onAuthorize() {
    if (!this.data.status) {
      const source$ = this.dialog
        .open<unknown, DialogConfirmData, boolean>(DialogConfirmComponent, {
          data: {
            title: 'Toestemming',
            message: 'U bent niet akkoord gegaan met de voorwaarden. Daardoor zal de Monitor niet van start kunnen gaan.<br/><br/> Weet u dit zeker?',
            okButton: 'Ja, ik ik ga niet akkoord',
          },
        })
        .afterClosed();

      const isOk = await firstValueFrom(source$, { defaultValue: false });
      if (!isOk) {
        return;
      }
    }

    const url = this.uri.loginUrl;
    this.loadingIndicator.showSpinner();

    switch (this.data.monitor) {
      case Monitor.Vmbo:
        await firstValueFrom(this.client.validateSchoolAuthorizeResponseVo(this.validationCode, this.data.status, url));
        break;
      case Monitor.Po:
        await firstValueFrom(this.client.validateSchoolAuthorizeResponsePo(this.validationCode, this.data.status, url));
        break;
      case Monitor.Mbo:
        await firstValueFrom(this.client.validateSchoolAuthorizeResponseMbo(this.validationCode, this.data.status, url));
        break;
      case Monitor.Pabo:
        await firstValueFrom(this.client.validateSchoolAuthorizeResponsePabo(this.validationCode, this.data.status, url));
        break;
    }

    this.formSaved = true;
  }

  private async getAuthorizeStatus() {
    this.data = await firstValueFrom(this.client.getAuthorizeStatus(this.validationCode));
    this.data.responseDate = this.data.status ? this.data.responseDate : null;
  }
}
