import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, provideRouter, UrlSerializer, UrlTree } from '@angular/router';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import { authConfig } from '@shared/feature/auth';
import { tokenInterceptor } from '@shared/util/code';
import { provideDataAdapter } from '@shared/util/date-adapter';
import { infrastructureConfig } from '@shared/util/infrastructure-config';
import { materialConfig } from '@shared/util/material';
import { translationsConfig, TranslocoHttpLoader } from '@shared/util/translations';
import { userConfig } from '@shared/util/user';
import { AppComponent } from './app/app.component';
import { ROUTES } from './app/routes';
import { RoleNl } from './app/shared/mdbos-user-roles';
import { environment } from './environments/environment';

const activeLanguage = 'nl-NL';
registerLocaleData(localeNl, activeLanguage);

class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // questionnaire links should be case insensitive
    return super.parse(url.replace(/\/(ph|bm|bp|bv|pc|pk|pl|pd|vc|vk|vl|vd|mc|ml|mk|mq|md|pac|pak|pal|paq|pad)\//gi, (x) => x.toUpperCase()));
  }
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    authConfig,
    userConfig(),
    infrastructureConfig(environment.apiUrl),
    translationsConfig(TranslocoHttpLoader.getTransLocoConfig(environment.production, activeLanguage), RoleNl),
    materialConfig(),
    provideDataAdapter(activeLanguage),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    provideLoadingBarRouter(),
    provideAnimations(),
    provideRouter(ROUTES),
    { provide: LOCALE_ID, useValue: activeLanguage },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
}).catch((err) => console.error(err));
