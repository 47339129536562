<lsu-report-paged [initialize]="isReady" [showPageNumbers]="true" [showPreview]="true">
  <lsu-report-paged-actions [fileName]="fileName" [generatePdfFromHTML]="true"></lsu-report-paged-actions>
  <lsu-report-paged-loader></lsu-report-paged-loader>
  <lsu-report-paged-page>
    <div class="w-100 text-center">
      <div class="mat-headline-5 mb-7">Lezen in beeld</div>
      <img [src]="coverContent.logo" width="200" class="mb-7" />
      <div class="mat-subtitle-1 mb-7">
        <strong>{{ coverContent.title }}</strong>
      </div>
      <div class="mat-body mb-7">Antwoorden op open vragen in de meting {{ year }}</div>
      <div *ngIf="locations.length" class="mb-7">
        <div class="mat-subtitle-1 mb-0" *ngFor="let location of locations">
          <strong>{{ location }}</strong>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <img [src]="baseURI + 'assets/images/Logo_SL_FC_RED.jpg'" height="100" class="me-4" />
        <img [src]="baseURI + 'assets/images/logo-kb.svg'" width="240" class="ms-4" />
      </div>
    </div>
  </lsu-report-paged-page>

  <lsu-report-paged-page *ngFor="let monitor of monitorWithOpenQuestions; let isLastMonitor = last">
    <div class="w-100 text-center">
      <div class="mat-subtitle-1 mb-7">
        <strong>{{ questionnaireTypeNl[monitor.questionnaireType] }}</strong>
      </div>
    </div>

    <div class="mb-4" *ngFor="let questions of monitor.openQuestions; let isLastQuestion = last">
      <div class="mat-subtitle-1 mb-0">
        <strong>{{ questions.description }}</strong>
      </div>

      <div *ngFor="let answer of questions.answers; let isLastAnswer = last">
        {{ answer }}
        <ng-container *ngIf="isLastAnswer && isLastMonitor && isLastQuestion && !isReady">
          {{ checkReadyForPreview() }}
        </ng-container>
      </div>
    </div>
  </lsu-report-paged-page>

  <lsu-report-paged-page *ngIf="!monitorWithOpenQuestions.length">
    <div class="w-100 text-center">
      <div class="mat-headline-6 mb-7"><strong> Er zijn geen open antwoorden gevonden. </strong></div>
      <div class="mat-subtitle-2">Voor geen van de vragenlijsten zijn open antwoorden gevonden.</div>
    </div>

    <ng-container *ngIf="!isReady">
      {{ checkReadyForPreview() }}
    </ng-container>
  </lsu-report-paged-page>

  <lsu-report-paged-page *ngFor="let monitor of monitorWithoutOpenQuestions">
    <div class="w-100 text-center">
      <div class="mat-subtitle-1 mb-7">
        <strong>{{ questionnaireTypeNl[monitor.questionnaireType] }}</strong>
      </div>
    </div>
    <div class="mat-subtitle-2">Voor deze vragenlijst zijn geen open antwoorden gevonden.</div>
    <ng-container *ngIf="!isReady">
      {{ checkReadyForPreview() }}
    </ng-container>
  </lsu-report-paged-page>
</lsu-report-paged>
