<div class="container g-0">
  <div class="row">
    <div class="col">
      <img src="./assets/images/logo-dbos.svg" alt="Monitor de Bibliotheek op school" style="border-width: 0px" />
    </div>
  </div>
  <div>
    <mat-card *ngIf="data">
      <ng-container *ngIf="formSaved"> Uw keuze is succesvol geregistreerd. U kunt dit venster nu sluiten. </ng-container>
      <ng-container *ngIf="!formSaved && data.status && data.responseDate">
        Uw keuze is succesvol geregistreerd op {{ data.responseDate | date: "d LLLL y 'om' H:mm" }}. U kunt dit venster nu sluiten.
      </ng-container>
      <ng-container *ngIf="!formSaved && (!data.status || !data.responseDate)">
        <form #form="ngForm">
          <mat-card-header>
            <mat-card-title>Privacy</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mt-3" *ngIf="data.monitor === monitor.Vmbo">
              Namens de directie van <strong>{{ data.locationName }}</strong> geef ik hierbij, als bevoegde vertegenwoordiger van
              <strong>{{ data.locationName }}</strong
              >, toestemming voor deelname aan de Monitor de Bibliotheek <i>op school</i>. In het kader van de monitor zullen mbo-studenten worden
              gevraagd deel te nemen aan een enquête. Deelname is vanzelfsprekend niet verplicht. De enquête wordt door mbo-studenten anoniem ingevuld
              waarbij alleen naar het leerjaar wordt gevraagd.
            </div>
            <div class="mt-3" *ngIf="data.monitor !== monitor.Vmbo">
              Namens de directie van <strong>{{ data.locationName }}</strong> geef ik hierbij, als bevoegde vertegenwoordiger van
              <strong>{{ data.locationName }}</strong
              >, toestemming voor deelname aan de Monitor de Bibliotheek <i>op school</i>. In het kader van de monitor zullen pabo-studenten worden
              gevraagd deel te nemen aan een enquête. Deelname is vanzelfsprekend niet verplicht. De enquête wordt door pabo-studenten anoniem
              ingevuld waarbij alleen naar de groepsnaam en leeftijd wordt gevraagd.
            </div>
            <div class="my-3">
              Voor het onderzoek binnen de Monitor de Bibliotheek <i>op school</i> hebben Stichting Lezen (als verwerkingsverantwoordelijke) en DESAN
              Marktonderzoek B.V. (als verwerker) een Verwerkersovereenkomst afgesloten in de zin van de Algemene Verordening Gegevensbescherming
              (AVG). Deze verwerkersovereenkomst is door een erkend jurist opgesteld. De verwerkersovereenkomst is volledig in lijn met de AVG die op
              25 mei 2018 in werking is getreden.
            </div>
            <mat-checkbox [(ngModel)]="data.status" labelPosition="after" name="akkoord"> Ik geef toestemming voor deelname. </mat-checkbox>
          </mat-card-content>
          <mat-card-actions>
            <button class="mr-2" mat-raised-button color="primary" (click)="onAuthorize()" pmaMdBos>Opslaan</button>
          </mat-card-actions>
        </form>
      </ng-container>
    </mat-card>
  </div>
</div>
