import { DOCUMENT, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagedActionsComponent, PagedComponent, PagedLoaderComponent, PagedPageComponent } from '@shared/ui/report';
import { safeLocalStorage } from '@shared/util/code';
import { UserError } from '@shared/util/infrastructure';
import { getMonitorType } from '../../../../core/translations/get-questionnaire-types';
import { ReportData, ReportMetaData } from '../../../../core/webapi';
import { SelectedLocations } from '../../../../shared/report-label-retriever';
import { ChartBarComponent } from '../chart-bar/chart-bar.component';
import { ChartColumnComponent } from '../chart-column/chart-column.component';
import { HighchartDataService, SomeHighchartsOptions } from '../highchart-data.service';
import { CoverContent, getCoverContent } from '../print';

@Component({
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PagedComponent,
    PagedActionsComponent,
    PagedLoaderComponent,
    PagedPageComponent,
    NgIf,
    NgFor,
    ChartColumnComponent,
    ChartBarComponent,
    KeyValuePipe,
  ],
})
export class PrintViewComponent {
  highchartData!: Record<number, SomeHighchartsOptions>;
  reportMetaData!: ReportMetaData;
  renderCount = 0;
  isReady = false;
  reflow = false;
  fileName!: string;
  selectedLocations!: SelectedLocations;
  coverContent!: CoverContent;
  reportData!: ReportData[];

  constructor(
    @Inject(DOCUMENT) document: Document,
    activatedRoute: ActivatedRoute,
    highchartDataService: HighchartDataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    const questionnaireType = +activatedRoute.snapshot.params['questionnaireType'];
    const result = safeLocalStorage.getItem('mdbos.report.result');
    const metaData = safeLocalStorage.getItem('mdbos.report.metaData');
    this.selectedLocations = JSON.parse(safeLocalStorage.getItem('mdbos.report.locations') ?? '{}');
    const selectedGroupsStorage = safeLocalStorage.getItem('mdbos.report.selectedGroups');
    const caseSelectionStorage = safeLocalStorage.getItem('mdbos.report.caseSelectie');
    const locationFilterLabel = safeLocalStorage.getItem('mdbos.report.locationFilterLabel');

    if (!result || !metaData || !caseSelectionStorage || !locationFilterLabel || !selectedGroupsStorage) {
      throw new UserError('Sluit deze pagina en probeer het opnieuw!', 'Er zijn geen grafiekresultaten gevonden in het geheugen.');
    }

    const monitorType = getMonitorType(questionnaireType);
    this.coverContent = getCoverContent(monitorType, document);
    let fileName = locationFilterLabel.replace(/(^"|"$)/g, '');
    if (!fileName) {
      fileName = 'report';
    }
    this.fileName = `${fileName}${!fileName.endsWith(')') ? '-' : ''}${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`;

    this.reportData = JSON.parse(result);
    const reportMetaData: ReportMetaData = JSON.parse(metaData);
    const caseSelection: string[] = JSON.parse(caseSelectionStorage);
    const selectedGroups: Record<number, string[]> = JSON.parse(selectedGroupsStorage);
    this.reportMetaData = reportMetaData;
    this.highchartData = highchartDataService.getData(this.reportData, reportMetaData, caseSelection, locationFilterLabel, selectedGroups);
  }

  originalOrder = (): number => {
    return 0;
  };

  isMultiResponseQuestion = (index: string): boolean =>
    this.reportMetaData.reportVariables.find((x) => x.variableId === this.reportData[Number(index)].reportVariableId)!.isMultiResponse;

  rendered() {
    this.renderCount++;
    if (this.renderCount === Object.keys(this.highchartData).length) {
      // make sure all charts are rendered before changing the html with pagedJs
      // first update de layout with isReady
      this.isReady = true;
      this.changeDetectorRef.detectChanges();
      // then reflow the charts to fit their new containers
      this.reflow = true;
      this.changeDetectorRef.detectChanges();
    }
  }
}
