import { Component, OnInit } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DialogConfirmComponent } from '@shared/ui/dialog';
import { DynamicTableComponent, IColumn, IRowCellClickEvent } from '@shared/ui/dynamic-table';
import { firstValueFrom } from 'rxjs';
import { translateProjectCode } from '../../../core/translations/project-code-nl';
import { UriService } from '../../../core/uri.service';
import { EntityNameStartCode, QuestionnaireProjectClient, QuestionnaireType, StartCodeClient } from '../../../core/webapi';

interface ICustom {
  import: boolean;
}

@Component({
  selector: 'pma-import-page',
  templateUrl: './import-page.component.html',
  imports: [MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle, MatCardContent, DynamicTableComponent],
})
export class ImportPageComponent implements OnInit {
  readonly columnDefinitions: Array<IColumn<EntityNameStartCode, ICustom>> = [
    {
      name: 'entityName',
      text: 'Naam',
    },
    {
      name: 'startCode',
      text: 'Startcode',
    },
    {
      name: 'import',
      sortFunc: null,
      filterFunc: null,
      type: 'icon',
      widths: 'large',
      toolTip: 'Kopieer de gegevens van deze vragenlijst.',
      text: 'Kopieer',
      valueFunc: () => 'file_copy',
    },
  ];

  readonly startCode: string;
  readonly isConsultant: boolean;
  readonly questionnaireType: QuestionnaireType;
  entityName!: string;
  entityType!: string;
  questionnaireUrl!: string;
  entities: EntityNameStartCode[] = [];
  projectGuid!: string;

  constructor(
    private uris: UriService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private startCodeClient: StartCodeClient,
    private client: QuestionnaireProjectClient,
  ) {
    this.startCode = this.route.snapshot.paramMap.get('id')!;
    this.questionnaireType = translateProjectCode(this.route.snapshot.url[0].path);
    this.isConsultant = [QuestionnaireType.PoConsultant, QuestionnaireType.VoConsultant].includes(this.questionnaireType);
    this.entityType = this.isConsultant ? 'school' : 'groep';
    this.columnDefinitions[0].text = this.isConsultant ? 'Schoolnaam' : 'Groepsnaam';
  }

  async actionButton(event: IRowCellClickEvent<EntityNameStartCode, ICustom>) {
    const row = event.row;

    switch (event.column.name) {
      case 'import':
        await this.copyValuesFrom(row.startCode, row.entityName);
        break;
      default:
        throw new Error('Unknown button.');
    }
  }

  async ngOnInit() {
    this.entities = this.isConsultant
      ? await firstValueFrom(this.startCodeClient.getCompletedQuestionnairesConsultant(this.startCode, this.questionnaireType))
      : await firstValueFrom(this.startCodeClient.getCompletedQuestionnairesTeacher(this.startCode, this.questionnaireType));
    this.entityName = this.isConsultant
      ? await firstValueFrom(this.startCodeClient.getSchoolLocationName(this.startCode))
      : await firstValueFrom(this.startCodeClient.getGroupName(this.startCode));
    this.projectGuid = await firstValueFrom(this.client.getProjectGuid(this.questionnaireType));
    this.questionnaireUrl = `${this.uris.urlResponse}${this.projectGuid}/login/${this.startCode}`;
  }

  async copyValuesFrom(fromStartCode: string, fromGroupName: string) {
    const data = {
      title: 'Gegevens kopiëren',
      message: `Weet je zeker dat je de antwoorden van de "${fromGroupName}" wilt kopiëren en gebruiken voor de huidige groep "${this.entityName}"? Je kunt maar één keer gebruik maken van de kopieer functie per school/groep.`,
      okButton: 'Ja, ik weet het zeker',
    };
    const isOk = await DialogConfirmComponent.open(this.dialog, { data });
    if (isOk) {
      await firstValueFrom(this.startCodeClient.copyAnswersFromQuestionnaire(this.projectGuid, fromStartCode, this.startCode));
      this.snackBar.open('De antwoorden zijn gekopieerd.');
      location.href = this.questionnaireUrl + '/MA';
    }
  }
}
